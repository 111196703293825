import { Color } from 'three';
import { defineComponent, inject, onMounted, provide, reactive, watch } from 'vue';

import * as env from '@resn/gozer-env';
import gsap from '@resn/gsap';

import { useFanatics } from './FanaticsProvider';

const ColorPaletteKey = 'ColorPalette';

export const useColorPalette = () => {
    const env = inject(ColorPaletteKey);
    if (!env) {
        throw new Error('useColorPalette must be used within a ColorPaletteProvider');
    }
    return env;
};

const createColors = () => [new Color(), new Color(), new Color()];

const ColorPaletteProvider = defineComponent({
    setup() {
        const colorsFrom = createColors();
        const colorsTo = createColors();
        const colorsFinal = createColors();

        const { colorPalette } = useFanatics();

        const tweens = { pr: 0 };

        const setColorPalette = () => {
            const colors = colorPalette.value;

            colors.forEach((color, i) => {
                colorsFrom[i].set(colorsFinal[i]);
                colorsTo[i].set(color);
            });

            gsap.killTweensOf(tweens);
            gsap.fromTo(
                tweens,
                { pr: 0 },
                {
                    pr: 1,
                    duration: 1,
                    onUpdate: () => {
                        colorsFinal.forEach((color, i) => {
                            color.lerpColors(colorsFrom[i], colorsTo[i], tweens.pr);
                        });
                    },
                }
            );
        };

        onMounted(setColorPalette);

        watch(colorPalette, setColorPalette);

        provide(ColorPaletteKey, colorsFinal);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default ColorPaletteProvider;
